<template lang="pug">
main.soldPage
  Slider(
    v-for='(listing, i) in soldListings',
    :key='listing.id',
    :index='i + 2',
    :listing='listing',
    sold,
    :reverse='i % 2'
  )
  Contact
</template>

<script>
  export default {
    data() {
      return {
        soldListings: null,
      };
    },
    components: {
      Slider: () => import('@/components/Slider.vue'),
      Contact: () => import('@/components/Contact.vue'),
    },
    async mounted() {
      this.soldListings = await this.$store.dispatch('getSoldListings');
      this.$store.commit('pageLoaded');
    },
  };
</script>

<style lang="scss"></style>
